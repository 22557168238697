
import {
  IonContent,
  IonButton,
  IonRadio,
  IonRadioGroup,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import DlvrInput from "../../../../components/DlvrInput.vue";
import { defineComponent, ref, onMounted, toRefs } from "vue";

export default defineComponent({
  components: {
    IonContent,
    DlvrInput,
    IonButton,
    IonRadio,
    IonRadioGroup,
    IonItem,
    IonLabel,
  },
  props: {
    close: { type: Function },
    disableVehicleOption: { type: Function },
    addVehicle: { type: Function },
    defaultData: { type: Object },
    isEdit: { type: Boolean },
  },
  setup(props) {
    const vehicleType = ref<string | undefined>("bike");
    const additionalFee = ref<number | undefined>(0);
    const { defaultData } = toRefs(props);
    onMounted(() => {
      if (defaultData.value !== undefined) {
        vehicleType.value = defaultData?.value?.type;
        additionalFee.value = defaultData?.value?.additionalFee;
      }
    });
    const selectVehicleType = (e: any) =>
      (vehicleType.value = e.target.value);
    return {
      selectVehicleType,
      vehicleType,
      additionalFee,
    };
  },
});
