
import { IonContent, IonItem } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Popover',
  components: { IonContent, IonItem },
  props: {
    onEdit: { type: Function },
    onDelete: { type: Function },
    close: { type: Function },
    data: { type: Object }
  }
});
