
import { defineComponent, ref, onMounted, toRefs } from 'vue';
import Joi from 'joi';
import DlvrInput from '@/components/DlvrInput.vue';
import DlvrSelect from '@/components/DlvrSelect.vue';
import AddVehicleModal from './AddVehicleModal.vue';
import Popover from './Popover.vue';
import { modal, popover } from '@/composables/ionicControllers';
import { states } from '@/lib/constants';
import { merchants } from '@/store';
import { Pricing, Vehicle } from '@/types/merchant';
import { loading, openToast } from '@/composables/ionicControllers';
import { validateFields } from '@/lib/forms';
import {
  IonContent,
  IonPage,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonBackButton,
  IonIcon,
  IonTitle,
  IonButton,
  IonItem,
} from '@ionic/vue';
import { addOutline, ellipsisVertical } from 'ionicons/icons';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    DlvrSelect,
    IonContent,
    IonPage,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonBackButton,
    DlvrInput,
    IonIcon,
    IonTitle,
    IonButton,
    IonItem,
  },
  setup() {
    const router = useRouter();
    const state = ref<string | undefined>('');
    const baseFare = ref<number | undefined>();
    const pricePerKm = ref<number | undefined>();
    const expressDeliveryCost = ref<number | undefined>();
    const vehicles = ref<Vehicle | undefined>({});
    const selectedStates = ref<any[]>([]);
    const pageTitle = router.currentRoute.value.params.id;

    onMounted(() => {
      const { merchant } = toRefs(merchants.state);
      if (merchant.value) {
        const { pricings } = merchant.value;
        if (pricings) {
          selectedStates.value = pricings.map(
            (pricing: Pricing) => pricing.state,
          );
        }
        if (pageTitle !== 'add-new') {
          if (pricings) {
            const data: Pricing | undefined = pricings.find(
              (elm: Pricing) => elm.state === pageTitle,
            );
            state.value = data?.state;
            baseFare.value = data?.baseFare;
            pricePerKm.value = data?.pricePerKm;
            expressDeliveryCost.value = data?.expressDeliveryCost;
            vehicles.value = data?.vehicles || {};
          }
        }
      }
    });

    const validationRules = {
      state: Joi.string().required(),
      baseFare: Joi.number().required(),
      pricePerKm: Joi.number().required(),
    };

    const pricingDetailsSchema = Joi.object(validationRules);

    const savePricing = async () => {
      const { error, value } = validateFields(
        pricingDetailsSchema,
        () => ({
          state: state.value,
          baseFare: baseFare.value,
          pricePerKm: pricePerKm.value,
        }),
        false,
      );

      if (vehicles.value && !Object.keys(vehicles?.value).length) {
        await openToast('Please select one or more vehicle options');
        return;
      }

      if (error) {
        console.log('error: ', error);
      } else {
        const loader = await loading();
        await loader.present();
        const data = {
          state: value.state,
          baseFare: value.baseFare,
          pricePerKm: value.pricePerKm,
          expressDeliveryCost: expressDeliveryCost.value
            ? expressDeliveryCost.value * 1
            : 0,
          vehicles: vehicles.value as Vehicle,
        };

        let response: any;

        if (pageTitle !== 'add-new') {
          response = await merchants.updateMerchantPricing(data);
        } else {
          response = await merchants.addMerchantPricing(data);
        }
        if (!response.ok) {
          await loader.dismiss();
          await openToast(response?.message);
        } else {
          router.back();
          await openToast(response.message);
          await loader.dismiss();
        }
      }
    };

    const addVehicle = (
      data: { type: string; additionalFee: number },
      isEdit?: boolean,
    ) => {
      if (isEdit) {
        if (vehicles.value) {
          const selected = vehicles.value[data.type];
          selected.additionalFee = data.additionalFee * 1;
          vehicles.value[data.type] = selected;
        }
        return;
      }
      if (vehicles.value) {
        const exists = vehicles.value[data.type];
        if (!exists) {
          data.additionalFee = data.additionalFee * 1;
          vehicles.value[data.type] = data;
          return;
        }
      }
    };
    const removeVehicle = (
      e: any,
      data: { type: string; additionalFee: number },
    ) => {
      if (vehicles.value) {
        const temp = vehicles.value;
        delete temp[data.type];
        vehicles.value = temp;
      }
    };
    const disableVehicleOption = (option: string) => {
      if (vehicles.value) {
        return vehicles.value[option];
      }
    };
    const disableStateOption = (value: string) =>
      selectedStates.value.includes(value);

    const openModal = async (
      e: any,
      defaultData: { type: string; additionalFee: number },
    ) => {
      if (!defaultData) {
        const md = await modal(
          AddVehicleModal,
          { disableVehicleOption, addVehicle },
          'task-detail-modal',
        );
        return await md.present();
      }
      const md = await modal(
        AddVehicleModal,
        {
          disableVehicleOption,
          addVehicle,
          defaultData,
          isEdit: true,
        },
        'task-detail-modal',
      );
      return await md.present();
    };
    const openPopover = async (
      e: Event,
      data: { type: string; additionalFee: number },
    ) =>
      popover(
        e,
        Popover,
        { onEdit: openModal, onDelete: removeVehicle, data },
        'popover',
      );

    return {
      state,
      pricePerKm,
      baseFare,
      vehicles,
      expressDeliveryCost,
      addOutline,
      ellipsisVertical,
      openModal,
      openPopover,
      disableStateOption,
      states,
      pageTitle,
      savePricing,
      validationRules,
    };
  },
});
